export interface Blog {
  id: string;
  title: string;
  content: string;
  createDate: string;
  lastModifiedDate: string;
  thumbnailUrl: string;
  category: BlogCategory;
  imgUrls: BlogImg[];
}

export interface BlogImg {
  id: string;
  name: string;
  url: string;
}

export enum BlogCategory {
  EVENT = 'EVENT',
  TECH = 'TECH',
  PRESS = 'PRESS',
  ETC = 'ETC',
  NEWS = 'NEWS',
}
