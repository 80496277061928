import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useObserver from '../../hooks/useObserver';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectMainMenu } from '../../modules/hompageSlice';
import { motion } from 'framer-motion';
import { opacityLeft } from '../../styles/animation';
import '../../css/section/location.scss';
import { scrollToTop, scrollToTopSmooth } from '../../util/scrollUtils';
import PAGE from '../../page/page';

function LocationBody() {
  const { ref: ref1, animation: animation1 } = useObserver(0.01);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectMainMenu = (menuName: string) => {
    dispatch(setSelectMainMenu(menuName));
    navigate(`/${menuName}`);
    scrollToTop();
  };

  // 메모이제이션하여 불필요한 재렌더링 방지
  const iframeSrc = useMemo(
    () =>
      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12664.146310482529!2d126.8929505!3d37.4834632!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9e2502da9117%3A0x1cc60bf2496104a0!2zKOyjvCnslYTsnbTsp4DslYTsnbTsvZTrpqzslYQ!5e0!3m2!1sko!2skr!4v1718779054096!5m2!1sko!2skr',
    []
  );

  const contactInfo = useMemo(
    () => (
      <>
        <p className="font-accent">
          <span>T.</span>+82.2.6959.0341
        </p>
        <p className="font-accent">
          <span>E.</span>customer@ontwins.com
        </p>
        <p className="font-accent">
          <span>F.</span>0505-099-0314
        </p>
      </>
    ),
    []
  );

  return (
    <motion.div
      ref={ref1}
      initial="hidden"
      animate={animation1}
      variants={opacityLeft}
      className="wrapper-body locationbody"
    >
      <div className="wrap-title-con">
        <div className="layout">
          <h1>Location</h1>
        </div>
      </div>

      <div className="wrap-con-con">
        <div className="con-header">
          <div className="layout">
            <iframe
              src={iframeSrc}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="con-body">
          <div className="layout">
            <div className="blink"></div>
            <div className="wrap-location-text">
              <h4>
                <span>A.</span>
                <Trans ns="location_body">
                  서울 구로구 디지털로27길 24 벽산디지털밸리 1차, 210호
                </Trans>
              </h4>
              <div>{contactInfo}</div>
            </div>
          </div>
        </div>
        <div className="con-footer">
          <div className="layout">
            <h3>Contact</h3>
            <button
              className="btn-big primary"
              onClick={() => selectMainMenu(PAGE.CONTACT)}
            >
              Contact
            </button>
          </div>
        </div>
      </div>
      <div className="nav-bottom"></div>
    </motion.div>
  );
}

export default React.memo(LocationBody);
