import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectMainMenu } from '../../modules/hompageSlice';
import { scrollToTop } from '../../util/scrollUtils';
import PATH from '../../router/path';
import PAGE from '../../page/page';

import { initialProjects } from '../../data/projects';

//css
import '../../css/section/solutionbody.scss';

const SolutionBodyProjects = () => {
  const half = Math.ceil(initialProjects.length / 2);
  const upperLineProjects = initialProjects.slice(0, half);
  const underLineProjects = initialProjects.slice(half);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickProjectCard = (projectId: number) => {
    dispatch(setSelectMainMenu(PAGE.PROJECTS_DETAIL));
    navigate(`${PATH.PROJECTS}/${projectId}`);
    scrollToTop();
  };

  return (
    <div className="wrap-solutions-project">
      <div className="con-header wrap-solutions-project-header">
        <div className="layout">
          <h2>Project</h2>
          <h4 className="center">
            <Trans ns="projects">
              저희는 성공적인 프로젝트 경험을 만들어냈습니다.
            </Trans>
          </h4>
        </div>
      </div>
      <div className="con-body wrap-solutions-project-body">
        <div className="wrap-solutions-project-box">
          {upperLineProjects.map((project) => (
            <div
              key={project.id}
              className="upper-project-item"
              onClick={() => handleClickProjectCard(project.id)}
            >
              <img src={project.image} alt={project?.detail?.title} />
            </div>
          ))}
          {upperLineProjects.map((project) => (
            <div
              key={project.id}
              className="upper-project-item"
              onClick={() => handleClickProjectCard(project.id)}
            >
              <img src={project.image} alt={project?.detail?.title} />
            </div>
          ))}
        </div>
        <div className="wrap-solutions-project-box">
          {underLineProjects.map((project) => (
            <div
              key={project.id}
              className="under-project-item"
              onClick={() => handleClickProjectCard(project.id)}
            >
              <img src={project.image} alt={project?.detail?.title} />
            </div>
          ))}
          {underLineProjects.map((project) => (
            <div
              key={project.id}
              className="under-project-item"
              onClick={() => handleClickProjectCard(project.id)}
            >
              <img src={project.image} alt={project?.detail?.title} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(SolutionBodyProjects);
