import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
// => Tiptap packages
import { useEditor, EditorContent } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextStyle from '@tiptap/extension-text-style';
// import Link from '@tiptap/extension-link';
import Bold from '@tiptap/extension-bold';
import Underline from '@tiptap/extension-underline';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Code from '@tiptap/extension-code';
import History from '@tiptap/extension-history';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Highlight from '@tiptap/extension-highlight';
import FontFamily from '@tiptap/extension-font-family';
import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import { Heading, Level } from '@tiptap/extension-heading';
import Image from '@tiptap/extension-image';
import Youtube from '@tiptap/extension-youtube';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Gapcursor from '@tiptap/extension-gapcursor';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
// Custom
import * as Icons from './BlogIcons';
import dayjs from 'dayjs';
import axios from 'axios';
import { Blog, BlogCategory } from '../../util/type/blogType';
// import { LinkModal } from './BlogLinkModal';

export const BlogEditor = ({
  blog,
  onFinishEditing,
}: {
  blog: Blog;
  onFinishEditing: () => void;
}) => {
  const [content, setContent] = useState(blog.content);
  const [title, setTitle] = useState(blog.title);
  const [category, setCategory] = useState(blog.category);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    setContent(blog.content);
    setTitle(blog.title || '');
  }, [blog]);
  // const htmlContent = content; // for debug 내용이 어떻게 보이는지 확인용
  BulletList.configure({ keepMarks: true });
  const editor = useEditor({
    extensions: [
      Document,
      History,
      Paragraph,
      Text,
      Bold,
      Underline,
      Italic,
      Strike,
      Code,
      ListItem,
      BulletList,
      OrderedList,
      Color,
      TextStyle,
      Heading,
      Image,
      Youtube,
      Highlight,
      Subscript,
      Superscript,
      FontFamily,
      Gapcursor,
      TextAlign,
      Placeholder.configure({
        placeholder: '내용을 작성해 주세요...',
      }),
    ],
    content,
  });

  const saveBlogHtml = useCallback(() => {
    // for debug
    // console.log('editor getHTML', editor.getHTML());
    const saveSignal = confirm('저장하시겠습니까?'); //eslint-disable-line
    if (saveSignal) {
      const blogContent = editor.getHTML();
      const postData: Blog = {
        id: blog.id,
        title,
        content: blogContent,
        createDate: blog.createDate ?? dayjs().format('YYYY-MM-DD HH:mm'),
        lastModifiedDate: dayjs().format('YYYY-MM-DD HH:mm'),
        thumbnailUrl: '',
        category,
        imgUrls: [],
      };
      // console.log('🚀 ~ postData:', postData);
      axios
        .post(
          'https://6rzwkgf5p8.execute-api.ap-northeast-2.amazonaws.com/igi/blog',
          postData
        )
        .then((res) => {
          console.log('🚀 ~ post blog res:', res);
          onFinishEditing();
        })
        .catch((e) => {
          console.log('🚀 ~ post blog error:', e);
        });
    }
  }, [editor, title, category, onFinishEditing]);

  const handleCancelBlogEdit = useCallback(() => {
    const cancelSiginal = confirm('수정을 취소하시겠습니까?'); //eslint-disable-line
    if (cancelSiginal) {
      onFinishEditing();
    }
  }, [onFinishEditing]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const title = event.target.value;
    setTitle(title);
  };

  const handleCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCategory = event.target.value as BlogCategory;
    setCategory(selectedCategory);
  };

  const handleFileInput = (event) => {
    const newFiles = Array.from(event.target.files);

    (newFiles as any).forEach((newFile) => {
      if (files.some((file) => file.name === newFile.name)) {
        alert(`파일 '${newFile.name}'은(는) 이미 추가되었습니다.`);
      } else {
        setFiles((prevFiles) => [...prevFiles, newFile]);
      }
    });
    event.target.value = null;
  };

  const handleRemove = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    // console.log('🚀 ~ remove files:', files);
  };

  const handleImgFileSubmit = async (event) => {
    event.preventDefault();

    // 파일을 Base64로 인코딩하고 파일명과 함께 객체에 저장
    const filesData = await Promise.all(
      files.map(
        (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              if (typeof reader.result === 'string') {
                resolve({
                  fileName: file.name,
                  fileContent: reader.result,
                });
              } else {
                reject(new Error('File reading was failed'));
              }
            };
            reader.readAsDataURL(file);
          })
      )
    );

    try {
      // 파일 데이터를 서버에 전송
      const response = await axios.post(
        'https://6rzwkgf5p8.execute-api.ap-northeast-2.amazonaws.com/igi/blog-img',
        filesData,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log('블로그 이미지 저장', response.data);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const toggleBold = useCallback(() => {
    editor.chain().focus().toggleBold().run();
  }, [editor]);

  const toggleUnderline = useCallback(() => {
    editor.chain().focus().toggleUnderline().run();
  }, [editor]);

  const toggleItalic = useCallback(() => {
    editor.chain().focus().toggleItalic().run();
  }, [editor]);

  const toggleStrike = useCallback(() => {
    editor.chain().focus().toggleStrike().run();
  }, [editor]);

  const toggleCode = useCallback(() => {
    editor.chain().focus().toggleCode().run();
  }, [editor]);

  const toggleOrderList = useCallback(() => {
    editor.chain().focus().toggleOrderedList().run();
  }, [editor]);

  const toggleBulletList = useCallback(() => {
    editor.chain().focus().toggleBulletList().run();
  }, [editor]);

  const setFontColor = useCallback(
    (color: string) => {
      editor.chain().focus().setColor(color).run();
    },
    [editor]
  );

  const unsetFontColor = useCallback(() => {
    editor.chain().focus().unsetColor().run();
  }, [editor]);

  const toggleHeading = useCallback(
    (level: Level) => {
      editor.chain().focus().toggleHeading({ level }).run();
    },
    [editor]
  );

  const addImage = useCallback(() => {
    const url = window.prompt('URL');

    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  }, [editor]);

  const addYoutubeVideo = useCallback(() => {
    const url = prompt(
      'URL을 입력해주세요.\n예) https://www.youtube.com/watch?v=HesxRs8czEo\n유튜브 영상은 공유버튼 클릭 후 퍼가기를 누르면 나오는 HTML 코드의 src="ULR"을 입력해주세요'
    );
    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
        // width: 640, // blog-editor.scss에서 크기 지정
        // height: 480,
      });
    }
  }, [editor]);

  const addHighlight = useCallback(() => {
    editor.chain().focus().toggleHighlight().run();
  }, [editor]);

  const addHighlightColor = useCallback(
    (color: string) => {
      editor.chain().focus().toggleHighlight({ color }).run();
    },
    [editor]
  );

  const toggleSubscript = useCallback(() => {
    editor.chain().focus().toggleSubscript().run();
  }, [editor]);

  const toggleSuperscript = useCallback(() => {
    editor.chain().focus().toggleSuperscript().run();
  }, [editor]);

  const setFontFamily = useCallback(
    (fontFamily: string) => {
      editor.chain().focus().setFontFamily(fontFamily).run();
    },
    [editor]
  );

  const setDefaultFontFamily = useCallback(() => {
    editor.chain().focus().unsetFontFamily().run();
  }, [editor]);

  const setTextAlign = useCallback(
    (align: TEXT_ALIGN) => {
      editor.chain().focus().setTextAlign(align).run();
    },
    [editor]
  );

  const unsetTextAlign = useCallback(() => {
    editor.chain().focus().unsetTextAlign().run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <>
      <div className="editor">
        <div className="insert" style={{ marginTop: '100px', height: '300px' }}>
          <form onSubmit={handleImgFileSubmit} encType="multipart/form-data">
            <input
              type="file"
              accept=".png, .jpg, .jpeg, .gif"
              onChange={handleFileInput}
              multiple
            />
            파일은 6MB이하로 업로드
            <div className="file-list">
              {files.map((file, index) => (
                <div key={index} className="filebox">
                  <p className="name">{file.name}</p>
                  <button
                    className="delete"
                    onClick={() => handleRemove(index)}
                  >
                    삭제
                  </button>
                </div>
              ))}
            </div>
            <button type="submit">등록</button>
          </form>
        </div>
        <input
          type="text"
          placeholder="타이틀을 작성 할 것!"
          onChange={handleTitleChange}
          value={title}
        />
        <select onChange={handleCategory} value={blog.category}>
          {Object.values(BlogCategory).map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
        <div className="menu">
          <button
            className="menu-button"
            onClick={() => editor.chain().focus().undo().run()}
            disabled={!editor.can().undo()}
          >
            <Icons.RotateLeft />
          </button>
          <button
            className="menu-button"
            onClick={() => editor.chain().focus().redo().run()}
            disabled={!editor.can().redo()}
          >
            <Icons.RotateRight />
          </button>
          <button
            className={classNames('menu-button', {
              'is-active': editor.isActive('bold'),
            })}
            onClick={toggleBold}
          >
            <Icons.Bold />
          </button>
          <button
            className={classNames('menu-button', {
              'is-active': editor.isActive('underline'),
            })}
            onClick={toggleUnderline}
          >
            <Icons.Underline />
          </button>
          <button
            className={classNames('menu-button', {
              'is-active': editor.isActive('intalic'),
            })}
            onClick={toggleItalic}
          >
            <Icons.Italic />
          </button>
          <button
            className={classNames('menu-button', {
              'is-active': editor.isActive('strike'),
            })}
            onClick={toggleStrike}
          >
            <Icons.Strikethrough />
          </button>
          {/* <button
            className={classNames('menu-button', {
              'is-active': editor.isActive('code'),
            })}
            onClick={toggleCode}
          >
            <Icons.Code />
          </button> */}
          <button
            className={classNames('menu-button', {
              'is-active': editor.isActive('orderedList'),
            })}
            onClick={toggleOrderList}
          >
            번호
          </button>
          <button
            className={classNames('menu-button', {
              'is-active': editor.isActive('bulletList'),
            })}
            onClick={toggleBulletList}
          >
            점
          </button>
          {/* <button
            className={classNames('menu-button')}
            onClick={unsetFontColor}
          >
            Black
          </button>
          <button
            className={classNames('menu-button', {
              'is-active': editor.isActive('textStyle', { color: '#FF0000' }),
            })}
            onClick={() => setFontColor('#FF0000')}
          >
            Red
          </button>
          <button
            className={classNames('menu-button', {
              'is-active': editor.isActive('textStyle', { color: '#0B60B0' }),
            })}
            onClick={() => setFontColor('#0B60B0')}
          >
            Blue
          </button> */}
          {/* <button
            className={classNames('menu-button')}
            onClick={() => toggleHeading(1)}
          >
            H1
          </button>
          <button
            className={classNames('menu-button')}
            onClick={() => toggleHeading(2)}
          >
            H2
          </button>
          <button
            className={classNames('menu-button')}
            onClick={() => toggleHeading(3)}
          >
            H3
          </button>
          <button
            className={classNames('menu-button')}
            onClick={() => toggleHeading(4)}
          >
            H4
          </button>
          <button
            className={classNames('menu-button')}
            onClick={() => toggleHeading(5)}
          >
            H5
          </button>
          <button
            className={classNames('menu-button')}
            onClick={() => toggleHeading(6)}
          >
            H6
          </button> */}
          <button className={classNames('menu-button')} onClick={addImage}>
            Img
          </button>
          <button
            className={classNames('menu-button')}
            onClick={addYoutubeVideo}
          >
            Video
          </button>
          <button
            className={classNames('menu-button', {
              'is-active': editor.isActive('highlight'),
            })}
            onClick={() => addHighlight()}
          >
            형광펜
          </button>
          {/* <button
            className={classNames('menu-button', {
              'is-active': editor.isActive('superscript'),
            })}
            onClick={toggleSuperscript}
          >
            윗첨자
          </button>
          <button
            className={classNames('menu-button', {
              'is-active': editor.isActive('subscript'),
            })}
            onClick={toggleSubscript}
          >
            아랫첨
          </button> */}
          {/* <button
            onClick={() => setFontFamily('Comic Sans MS, Comic Sans')}
            className={
              editor.isActive('textStyle', {
                fontFamily: 'Comic Sans MS, Comic Sans',
              })
                ? 'is-active'
                : ''
            }
          >
            Comic Sans
          </button>
          <button
            onClick={() => setFontFamily('serif')}
            className={
              editor.isActive('textStyle', { fontFamily: 'serif' })
                ? 'is-active'
                : ''
            }
          >
            serif
          </button>
          <button
            onClick={() => () => setFontFamily('monospace')}
            className={
              editor.isActive('textStyle', { fontFamily: 'monospace' })
                ? 'is-active'
                : ''
            }
          >
            monospace
          </button>
          <button
            onClick={() => () => setFontFamily('cursive')}
            className={
              editor.isActive('textStyle', { fontFamily: 'cursive' })
                ? 'is-active'
                : ''
            }
          >
            cursive
          </button> */}
          {/* <button onClick={setDefaultFontFamily}>Default Font</button> */}
          {/* <button
            onClick={() => setTextAlign(TEXT_ALIGN.right)}
            className={
              editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''
            }
          >
            Align right
          </button>
          <button
            onClick={() => setTextAlign(TEXT_ALIGN.left)}
            className={
              editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''
            }
          >
            Align left
          </button>
          <button
            onClick={() => setTextAlign(TEXT_ALIGN.center)}
            className={
              editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''
            }
          >
            Align center
          </button>
          <button
            onClick={() => setTextAlign(TEXT_ALIGN.justify)}
            className={
              editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''
            }
          >
            Align justify
          </button>
          <button onClick={unsetTextAlign}>Align reset</button> */}
          <button onClick={saveBlogHtml}>저장하기</button>
          <button onClick={handleCancelBlogEdit}>수정취소</button>
        </div>
        <EditorContent editor={editor} />
      </div>
      {/* HTML 내용이 사용자로부터 입력받은 값이라면 XSS 공격에 취약할 수 있으니 주의 */}
      {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
    </>
  );
};

enum TEXT_ALIGN {
  left = 'left',
  center = 'center',
  right = 'right',
  justify = 'justify',
}
