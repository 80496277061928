import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import axiosInstance from '../../util/axiosInterceptor';
import { setSelectMainMenu } from '../../modules/hompageSlice';
import { BlogEditor } from '../blog/BlogEditor';
import { opacityLeft } from '../../styles/animation';
import { motion } from 'framer-motion';
import { Blog, BlogCategory } from '../../util/type/blogType';
import '../../css/blog-editor.scss';
import axios from 'axios';
import parse from 'html-react-parser';
import { initialBlogs } from '../../data/blogs';
import useObserver from '../../hooks/useObserver';

//css
import '../../css/section/blogbody.scss';

function BlogBodyDetail() {
  const { id } = useParams();

  const [blog, setBlog] = useState<Blog>(null);
  const [editingBlog, setEditingBlog] = useState<Blog | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const { ref: ref1, animation: animation1 } = useObserver(0.01);

  useEffect(() => {
    getBlogContentMock();
    // getBlogContent(); // AWS
  }, [id]);

  const getBlogContentMock = () => {
    const blog = initialBlogs.find((blog) => {
      return blog.id === id;
    });
    setBlog(blog);
  };

  const getBlogContent = async (): Promise<any> => {
    await axios
      .get(
        `https://6rzwkgf5p8.execute-api.ap-northeast-2.amazonaws.com/igi/blog/${id}`
      )
      .then((res) => {
        const data = res?.data;
        if (data) {
          console.log('🚀 ~ data:', data);
          setBlog(data);
        }
      })
      .catch((_) => {
        console.log('🚀 ~ get blog error:', _);
      });
  };

  const handleEditClick = (blog: Blog) => {
    const userConfirmed = confirm('수정하시겠습니까?'); //eslint-disable-line
    if (userConfirmed) {
      setEditingBlog(blog);
      setIsEditing(true);
    }
  };

  if (isEditing && editingBlog) {
    const handleFinishEditing = () => {
      setIsEditing(false);
      getBlogContent();
    };
    return (
      <BlogEditor blog={editingBlog} onFinishEditing={handleFinishEditing} />
    );
  }

  const handleDelteClick = (blog: Blog) => {
    const deleteSignal = confirm('삭제하시겠습니까?'); //eslint-disable-line
    if (deleteSignal) {
      axios
        .delete(
          'https://6rzwkgf5p8.execute-api.ap-northeast-2.amazonaws.com/igi/blog',
          { data: blog }
        )
        .then((res) => {
          console.log('🚀 ~ delete blog res:', res);
        })
        .catch((e) => {
          console.log('🚀 ~ delete blog error:', e);
        });
    }
  };

  // if (!blog) {
  //   return <p>Loading...</p>;
  // }

  return (
    <>
      <motion.div
        ref={ref1}
        initial="hidden"
        animate={animation1}
        variants={opacityLeft}
        className="wrapper-body blogbody detail"
      >
        <div className="layout">
          <div className="wrap-title-con wrap-blog-title">
            <p className="font-big">{blog?.createDate}</p>
            <h3>{blog?.title}</h3>
          </div>

          <div
            className="wrap-con-con wrap-blog-con"
            // dangerouslySetInnerHTML={{ __html: blog?.content }}
          >
            {blog?.content ? parse(blog.content) : null}
          </div>
        </div>
        <div className="nav-bottom">
          {/* <button className="btn-big" onClick={() => handleEditClick(blog)}>
            Edit
          </button>
          <button className="btn-circle" onClick={() => handleDelteClick(blog)}>
            Delete
          </button> */}
        </div>
      </motion.div>
    </>
  );
}

export default React.memo(BlogBodyDetail);
