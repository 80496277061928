import { createSlice } from '@reduxjs/toolkit';
import PAGE from '../page/page';

const userSlice = createSlice({
  name: 'hompage',
  initialState: {
    selectMainMenu: PAGE.MAIN,
    showAdminLogin: false,
    adminLogin: false,
  },
  reducers: {
    setSelectMainMenu(state, action) {
      state.selectMainMenu = action.payload;
    },
    setShowAdminLogin(state, action) {
      state.showAdminLogin = !state.showAdminLogin;
    },
    setAdminLogin(state, action) {
      state.adminLogin = action.payload;
      state.showAdminLogin = !state.showAdminLogin;
    },
  },
});

export const { setSelectMainMenu, setShowAdminLogin, setAdminLogin } =
  userSlice.actions;
export default userSlice.reducer;
