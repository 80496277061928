import HeaderHomepage from '../components/common/Header';
import BodyHomepage from '../components/common/Body';
import FooterHomepage from '../components/common/Footer';
import React, { useEffect, useState } from 'react';

import i18n from '../locales/i18n';
import { useTranslation } from 'react-i18next';

// css
import '../css/setting/_reset.scss';
import '../css/setting/_fonts.scss';
import '../css/setting/_icons.scss';
import '../css/style.scss';

function Main() {
  const { t } = useTranslation();
  // const [isLanguageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false);
  const [resizing, setResizing] = useState(true); // resizing 상태를 useState로 관리

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    // setLanguageMenuOpen(false);
  };

  // useEffect(() => {
  //   let timeoutId: NodeJS.Timeout;

  //   const handleResize = () => {
  //     // 기존에 등록된 timeout을 clear
  //     clearTimeout(timeoutId);

  //     // 300ms 후에 리사이징 이벤트 처리
  //     timeoutId = setTimeout(() => {
  //       if (window.innerWidth >= 1920) {
  //         console.log('Resize completed for screens wider than 920px');
  //         setResizing(false); // 리사이징 완료 시 resizing 상태를 false로 변경
  //       }
  //     }, 300);
  //   };

  //   // 초기 로딩 시 한 번 실행
  //   handleResize();

  //   // 리사이징 이벤트 핸들러 등록
  //   window.addEventListener('resize', handleResize);

  //   // 컴포넌트 언마운트 시 리사이징 이벤트 제거
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []); // 빈 배열을 전달하여 한 번만 실행되도록 함

  return (
    <div className="main">
      <HeaderHomepage />
      {/* 언어 선택 메뉴 */}
      {/* 
      <ul className="header-gnb-nav-link-dropDown">
        <li
          className="header-gnb-nav-link-dropDown-item"
          onClick={() => changeLanguage('ko')}
        >
          한국어
        </li>
        <li
          className="header-gnb-nav-link-dropDown-item"
          onClick={() => changeLanguage('en')}
        >
          English
        </li>
      </ul>
      */}

      {/* resizing이 false일 때 BodyHomepage을 렌더링 */}
      {/* {!resizing && <BodyHomepage />} */}
      <BodyHomepage />

      <FooterHomepage />
    </div>
  );
}

export default React.memo(Main);
