import { useEffect, useCallback } from 'react';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import debounce from 'lodash/debounce';

const useObserver = (threshold) => {
  const animation = useAnimation();
  const { ref, inView } = useInView({ threshold });

  const handleInViewChange = useCallback(
    debounce((inView) => {
      if (inView) {
        animation.start('visible');
      } else {
        animation.start('hidden');
      }
    }, 100),
    [animation]
  );

  useEffect(() => {
    handleInViewChange(inView);
  }, [inView, handleInViewChange]);

  return { ref, animation };
};

export default useObserver;
