import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import {
  setSelectMainMenu,
  setShowAdminLogin,
} from '../../modules/hompageSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { scrollToTop } from '../../util/scrollUtils';

import '../../css/section/header.scss';
import PATH from '../../router/path';
import PAGE from '../../page/page';
import i18n from '../../locales/i18n';
import { useTranslation } from 'react-i18next';

const HeaderHomepage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SHOWROOM_URL = 'http://3.34.244.14/';
  const selectMainMenu = (menuName: string) => {
    dispatch(setSelectMainMenu(menuName));
    navigate(menuName);
    scrollToTop();
  };

  const selectMobileMainMenu = (menuName: string) => {
    dispatch(setSelectMainMenu(menuName));
    navigate(menuName);
    scrollToTop();

    toggleMobileNav();
    setIsAboutUsOpen(false);
    setIsSolutionOpen(false);
  };

  const adminLogin = useSelector(
    (state: RootState) => state.hompate.adminLogin
  );
  const showAdminLogin = useSelector(
    (state: RootState) => state.hompate.showAdminLogin
  );

  const clickShowroom = () => {
    window.open(SHOWROOM_URL, '_blank');
  };

  const { t } = useTranslation();
  const langKorRef = useRef<HTMLButtonElement | null>(null);
  const langEngRef = useRef<HTMLButtonElement | null>(null);
  const KOR_LANG = 'ko';
  const ENG_LANG = 'en';
  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    if (lang === KOR_LANG) {
      langKorRef.current.className = 'font-menu selected';
      langEngRef.current.className = 'font-menu';
    } else {
      langKorRef.current.className = 'font-menu';
      langEngRef.current.className = 'font-menu selected';
    }
  };

  const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);
  const [isAboutUsOpen, setIsAboutUsOpen] = useState<boolean>(false);
  const [isSolutionOpen, setIsSolutionOpen] = useState<boolean>(false);
  const headerRef = useRef<HTMLHeadElement | null>(null);
  const [headerClassname, setHeaderClassname] = useState<string>('');
  const location = useLocation();

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const toggleAboutUs = () => {
    setIsAboutUsOpen(!isAboutUsOpen);
  };

  const toggleSolution = () => {
    setIsSolutionOpen(!isSolutionOpen);
  };

  useEffect(() => {
    const updateHeaderClassname = () => {
      setHeaderClassname('header no-background');
    };

    const handleScroll = () => {
      const isHomeOrMain =
        location.pathname === PATH.HOME || location.pathname === PATH.MAIN;
      const scrollThreshold = isHomeOrMain
        ? window.innerHeight
        : window.innerHeight * 0.2;
      const shouldRemoveBackground = window.scrollY >= scrollThreshold;

      if (shouldRemoveBackground) {
        setHeaderClassname('header');
      } else {
        updateHeaderClassname();
      }
    };

    updateHeaderClassname();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  return (
    <header ref={headerRef} className={headerClassname}>
      <a className="logo-header logo-black" href="/">
        <span></span>
      </a>
      <nav className="nav-web">
        {/* <div onClick={() => dispatch(setShowAdminLogin(false))}>
          <span className="font-menu">로그인</span>
        </div> */}
        {adminLogin && (
          <div onClick={() => selectMainMenu(PAGE.ADMIN)}>Admin</div>
        )}
        <ul className="wrap-web-menu">
          <li className="con-web-menu">
            <ul className="con-web-menu-list">
              <li
                className="font-menu"
                onClick={() => selectMainMenu(PAGE.ABOUT_US)}
              >
                About Us
              </li>
              <li
                className="font-menu"
                onClick={() => selectMainMenu(PAGE.LOCATION)}
              >
                Location
              </li>
            </ul>
            <span
              className="con-web-menu-title font-menu"
              onClick={() => selectMainMenu(PAGE.ABOUT_US)}
            >
              About Us +
            </span>
          </li>
          <li className="con-web-menu">
            <ul className="con-web-menu-list">
              <li
                className="font-menu"
                onClick={() => selectMainMenu(PAGE.SOL_DT)}
              >
                Digital Twin
              </li>
              <li
                className="font-menu"
                onClick={() => selectMainMenu(PAGE.SOL_M)}
              >
                Metaverse
              </li>
              <li
                className="font-menu"
                onClick={() => selectMainMenu(PAGE.SOL_VE)}
              >
                Virtual
                <br />
                Exhibition
              </li>
            </ul>
            <span
              className="con-web-menu-title font-menu"
              onClick={() => selectMainMenu(PAGE.SOL_DT)}
            >
              Solutions +
            </span>
          </li>
          <li
            className="con-web-menu"
            onClick={() => selectMainMenu(PAGE.PROJECTS)}
          >
            <span className="con-web-menu-title font-menu">Projects</span>
          </li>
          <li
            className="con-web-menu"
            onClick={() => selectMainMenu(PAGE.BLOG)}
          >
            <span className="con-web-menu-title font-menu">Blog</span>
          </li>
          <li
            className="con-web-menu"
            onClick={() => selectMainMenu(PAGE.CONTACT)}
          >
            <span className="con-web-menu-title font-menu">Contact</span>
          </li>
          {adminLogin && (
            <div onClick={() => selectMainMenu(PAGE.ADMIN)}>Admin</div>
          )}
        </ul>
        <a className="btn-showroom" onClick={clickShowroom} target="_blank">
          <span className="btn-img btn-showroom-img">
            <span className="img-vitual-exhibition"></span>
          </span>
          <span className="btn-showroom-text font-menu">Showroom</span>
        </a>
        <div className="btn-lang">
          <button
            ref={langKorRef}
            className="font-menu selected"
            onClick={() => changeLanguage(KOR_LANG)}
          >
            KR
          </button>
          <button
            ref={langEngRef}
            className="font-menu"
            onClick={() => changeLanguage(ENG_LANG)}
          >
            EN
          </button>
        </div>
      </nav>
      <nav className="nav-mobile">
        <button
          className={`btn-nav-mobile ${isMobileNavOpen ? 'selected' : ''}`}
          onClick={toggleMobileNav}
        >
          <span className="btn-nav-mobile-line"></span>
          <span className="btn-nav-mobile-line"></span>
        </button>
        <ul className="wrap-mobile-menu">
          <li className="con-mobile-menu">
            <h3
              className={`${isAboutUsOpen ? 'selected' : ''}`}
              onClick={toggleAboutUs}
            >
              About Us+
            </h3>
            <ul className="con-mobile-menu-list">
              <li
                className="font-menu"
                onClick={() => selectMobileMainMenu(PAGE.ABOUT_US)}
              >
                About Us
              </li>
              <li
                className="font-menu"
                onClick={() => selectMobileMainMenu(PAGE.LOCATION)}
              >
                Location
              </li>
            </ul>
          </li>
          <li className="con-mobile-menu">
            <h3
              className={`${isSolutionOpen ? 'selected' : ''}`}
              onClick={toggleSolution}
            >
              Solutions +
            </h3>
            <ul className="con-mobile-menu-list">
              <li
                className="font-menu"
                onClick={() => selectMobileMainMenu(PAGE.SOL_DT)}
              >
                Digital Twin
              </li>
              <li
                className="font-menu"
                onClick={() => selectMobileMainMenu(PAGE.SOL_M)}
              >
                Metaverse
              </li>
              <li
                className="font-menu"
                onClick={() => selectMobileMainMenu(PAGE.SOL_VE)}
              >
                Virtual Exhibition
              </li>
            </ul>
          </li>
          <li
            className="con-mobile-menu"
            onClick={() => selectMobileMainMenu(PAGE.PROJECTS)}
          >
            <h3>Projects</h3>
          </li>
          <li
            className="con-mobile-menu"
            onClick={() => selectMobileMainMenu(PAGE.BLOG)}
          >
            <h3>Blog</h3>
          </li>
          <li
            className="con-mobile-menu"
            onClick={() => selectMobileMainMenu(PAGE.CONTACT)}
          >
            <h3>Contact</h3>
          </li>
          {adminLogin && (
            <div onClick={() => selectMobileMainMenu(PAGE.ADMIN)}>Admin</div>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default React.memo(HeaderHomepage);
