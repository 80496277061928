import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import { RootState } from './modules';
import { Cookies } from 'react-cookie';
// import { AuthContext } from '../context/auth-context';

// 로그인 유저만 접근 가능
// 비로그인 유저 접근 불가
const PrivateRoute = () => {
  const navigate = useNavigate();
  // const isLogin = useSelector((state: RootState) => state.user.isLogin);
  // useEffect(() => {
  //   if (!isLogin) {
  //     logout();
  //   }
  // }, [isLogin]);

  const token = localStorage.getItem('IGIaccessToken');
  // const token = useSelector((state: RootState) => state.user.userID);

  const logout = () => {
    localStorage.removeItem('IGIaccessToken');
    localStorage.removeItem('IGIrefreshToken');
    // navigate('/signin');
  };

  if (!token) {
    alert('로그인이 필요한 기능입니다.');
  }
  return token ? <Outlet /> : <Navigate to="/signin" />;
};

export default PrivateRoute;
