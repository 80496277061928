import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { opacityLeft } from '../../styles/animation';
import { motion } from 'framer-motion';
import useObserver from '../../hooks/useObserver';
import {
  InitialProjects,
  CATEGORY,
  SOURCE_TYPE,
} from '../../util/type/projectType';
import { initialProjects } from '../../data/projects';

//css
import '../../css/section/projectsbody.scss';
import PATH from '../../router/path';
import { Trans } from 'react-i18next';

function ProjectsBodyDetail() {
  const { ref: ref1, animation: animation1 } = useObserver(0.1);
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialProject, setInitialProject] = useState<InitialProjects>(null);

  useEffect(() => {
    const project = initialProjects.find((data) => data.id === parseInt(id));
    if (project) {
      setInitialProject(project);
    } else {
      navigate(PATH.PROJECTS);
    }
  }, [id, navigate]);

  const handleOnPlayClick = useCallback(() => {
    window.open(initialProject.playLink, '_blank');
  }, [initialProject]);

  if (!initialProject) {
    return <p>Loading...</p>;
  }

  const categoriesToShow = initialProject.category.includes(CATEGORY.ALL)
    ? Object.values(CATEGORY).filter((category) => category !== CATEGORY.ALL)
    : initialProject.category;

  const highlightList = initialProject?.detail?.HighlightImg;

  return (
    <motion.div
      ref={ref1}
      initial="hidden"
      animate={animation1}
      variants={opacityLeft}
      className="wrapper-body projectsbody detail"
    >
      <div className="wrap-title-con wrap-projects-title">
        <div className="layout">
          <h4>
            <Trans ns="projects">{initialProject?.detail?.subTitle}</Trans>
          </h4>
          <h2>
            <Trans ns="projects">{initialProject?.detail?.title}</Trans>
          </h2>
        </div>
      </div>
      <div className="wrap-con-con wrap-projects-con">
        <div className="layout">
          <div className="con-header wrap-projects-header">
            <div className="con-project-main">
              {initialProject?.detail?.sourceType === SOURCE_TYPE.VIDEO ? (
                <video
                  controlsList="nodownload"
                  controls
                  src={initialProject?.detail?.source}
                  autoPlay
                  muted
                />
              ) : (
                <img src={initialProject?.detail?.source} />
              )}
            </div>
          </div>
          <div className="con-body wrap-projects-body">
            <div className="con-projects-des">
              <div className="con-projects-info">
                <dl>
                  <dt className="font-medium">
                    <Trans ns="projects">프로젝트 유형</Trans>
                  </dt>
                  {categoriesToShow?.map((type, idx) => (
                    <dd className="font-accent" key={idx}>
                      {type}
                    </dd>
                  ))}
                </dl>
                <dl>
                  <dt className="font-medium">
                    <Trans ns="projects">프로젝트 착수</Trans>
                  </dt>
                  <dd className="font-accent">{initialProject?.period}</dd>
                </dl>
              </div>
              {initialProject?.playLink ? (
                <button className="btn-big" onClick={handleOnPlayClick}>
                  Play
                </button>
              ) : null}
            </div>
          </div>
          {highlightList?.length > 1 ? (
            <div className="con-footer wrap-projects-footer">
              <h2>Highlight </h2>
              <div className="wrap-highlight">
                {highlightList?.map((highlight, idx) => (
                  <div className="con-highlight">
                    <img key={idx} src={highlight} />
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="nav-bottom">
        {/* <button className="btn-big" onClick={() => handleEditClick(blog)}>
            Edit
          </button>
          <button className="btn-circle" onClick={() => handleDelteClick(blog)}>
            Delete
          </button> */}
      </div>
    </motion.div>
  );
}

export default React.memo(ProjectsBodyDetail);
