import { configureStore } from '@reduxjs/toolkit';
import hompageSliceReducer from './hompageSlice';
const myMiddleware = (store) => (next) => (action) => {
  // 미들웨어에서 수행할 작업
  // action을 가로채고 처리하는 코드 작성
  // console.log('action', action);

  // 'projects'로 시작하는 action type만 막기
  // if (
  //   action.type.startsWith('projects') &&
  //   action.type !== 'projects/setPointer' &&
  //   action.type !== 'projects/setProject'
  // ) {
  //   return; // dispatch를 막기 위해 아무 작업도 하지 않고 바로 리턴합니다.
  // }
  const result = next(action);

  // 필요한 경우 추가적인 작업 수행

  // 액션 또는 결과를 반환
  return result;
};

const store = configureStore({
  reducer: {
    hompate: hompageSliceReducer,
  },
  middleware: [myMiddleware], // 직접 만든 미들웨어 추가
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
