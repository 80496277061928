import React, { useEffect, useState, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import { setSelectMainMenu } from '../../modules/hompageSlice';
import { BlogEditor } from '../blog/BlogEditor';
import { opacityLeft } from '../../styles/animation';
import { motion } from 'framer-motion';
import { Blog, BlogCategory } from '../../util/type/blogType';
import axios from 'axios';
import PATH from '../../router/path';
import PAGE from '../../page/page';
import { initialBlogs } from '../../data/blogs';
import parse from 'html-react-parser';
import useObserver from '../../hooks/useObserver';
import { scrollToTop, scrollToTopSmooth } from '../../util/scrollUtils';
import { v4 } from 'uuid';

//css
import '../../css/section/blogbody.scss';
import '../../css/blog-editor.scss';

function BlogBody({}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectMainMenu = (menuName, blogId) => {
    dispatch(setSelectMainMenu(menuName));
    navigate(`${PATH.BLOG}/${blogId}`);
    scrollToTop();
  };

  const handleGoToTop = useCallback(() => {
    scrollToTopSmooth();
  }, []);

  const [blogList, setBlogList] = useState<Blog[]>([]);
  const [editingBlog, setEditingBlog] = useState<Blog | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  const showValue = 12; // 한 번에 보여줄 블로그 수
  const [displayCount, setDisplayCount] = useState(showValue);
  const [blogType, setBlogType] = useState<BlogCategory | string>('ALL');
  const { ref: ref1, animation: animation1 } = useObserver(0.01);

  useEffect(() => {
    getBlogContentsMock();
    // getBlogContents(); // AWS
  }, []);

  const getBlogContentsMock = () => {
    setBlogList(initialBlogs);
  };

  const getBlogContents = async (): Promise<any> => {
    await axios
      .get(
        'https://6rzwkgf5p8.execute-api.ap-northeast-2.amazonaws.com/igi/blog'
      )
      .then((res) => {
        const blogList = res?.data?.Items.sort((a, b) => {
          const dateA = a.createDate ? new Date(a.createDate).getTime() : 0;
          const dateB = b.createDate ? new Date(b.createDate).getTime() : 0;
          return dateB - dateA;
        });
        setBlogList(blogList || []);
      })
      .catch((_) => {
        setBlogList([]);
        // console.log('🚀 ~ get blog error:', _);
      });
  };

  const getBlogThumnailImg = (content: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const firstImg = doc.querySelector('img');

    return firstImg ? firstImg.src : '';
  };

  const getBlogThumnailContents = (content: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const textContent = doc.body.textContent || '';

    return textContent.replace(/\s+/g, ' ').trim();
  };

  const handleNewClick = () => {
    const userConfirmed = confirm('작성하시겠습니까?'); //eslint-disable-line
    if (userConfirmed) {
      setEditingBlog({
        id: v4(),
        title: null,
        content: null,
        createDate: null,
        lastModifiedDate: null,
        thumbnailUrl: null,
        category: BlogCategory.ETC,
        imgUrls: [],
      });
      setIsEditing(true);
    }
  };

  const filteredBlogList = blogList
    .filter((blog) => blogType === 'ALL' || blog.category === blogType)
    .slice(0, displayCount);

  const handleCategoryClick = (category) => {
    setBlogType(category);
    setDisplayCount(showValue);
    scrollToTop();
  };

  const handleScroll = () => {
    if (!window.requestAnimationFrame) {
      updateScroll();
    } else {
      window.requestAnimationFrame(updateScroll);
    }
  };

  // 스크롤 이벤트 핸들러
  const updateScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight * 0.6
    ) {
      setDisplayCount((prevCount) => prevCount + showValue);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (isEditing && editingBlog) {
    const handleFinishEditing = () => {
      setIsEditing(false);
      getBlogContents();
    };
    return (
      <BlogEditor blog={editingBlog} onFinishEditing={handleFinishEditing} />
    );
  }

  return (
    <>
      <motion.div
        ref={ref1}
        initial="hidden"
        animate={animation1}
        variants={opacityLeft}
        className="wrapper-body blogbody"
      >
        <div className="layout">
          <div className="wrap-title-con wrap-blog-title">
            <h1>Blog</h1>
            <ul className="wrap-title-srot">
              <li
                className={`con-title-srot ${
                  blogType === 'ALL' ? 'active' : ''
                }`}
                onClick={() => handleCategoryClick('ALL')}
              >
                <p className="font-accent">ALL</p>
              </li>
              {/* <li
                className={`con-title-srot ${
                  blogType === BlogCategory.NEWS ? 'active' : ''
                }`}
                onClick={() => handleCategoryClick(BlogCategory.NEWS)}
              >
                <p className="font-accent">News</p>
              </li> */}
              <li
                className={`con-title-srot ${
                  blogType === BlogCategory.EVENT ? 'active' : ''
                }`}
                onClick={() => handleCategoryClick(BlogCategory.EVENT)}
              >
                <p className="font-accent">Event</p>
              </li>
              <li
                className={`con-title-srot ${
                  blogType === BlogCategory.TECH ? 'active' : ''
                }`}
                onClick={() => handleCategoryClick(BlogCategory.TECH)}
              >
                <p className="font-accent">Tech</p>
              </li>
              <li
                className={`con-title-srot ${
                  blogType === BlogCategory.PRESS ? 'active' : ''
                }`}
                onClick={() => handleCategoryClick(BlogCategory.PRESS)}
              >
                <p className="font-accent">Press</p>
              </li>
              <li
                className={`con-title-srot ${
                  blogType === BlogCategory.ETC ? 'active' : ''
                }`}
                onClick={() => handleCategoryClick(BlogCategory.ETC)}
              >
                <p className="font-accent">ETC</p>
              </li>
            </ul>
          </div>

          <div className="wrap-con-con wrap-blog-con">
            <div className="con-body wrap-blog-body">
              <div className="wrap-blog-card">
                {/* 블로그 리스트 출력 */}
                {filteredBlogList.map((blog, index) => (
                  <div
                    key={index}
                    className="con-blog-card"
                    onClick={() => selectMainMenu(PAGE.BLOG_DETAIL, blog.id)}
                  >
                    <div className="con-blog-des">
                      <p className="font-small">{blog.category}</p>
                      <p className="font-big">
                        <Trans ns="blog_body">{blog.title}</Trans>
                      </p>
                      <p>{getBlogThumnailContents(blog.content)}</p>
                    </div>
                    <div className="con-blog-img">
                      {/* 본문의 첫 번째 이미지를 썸네일로 쓸 경우 */}
                      <img src={getBlogThumnailImg(blog.content)} alt="" />
                      {/* thumnailUrl로 썸네일 이미지를 지정할 경우 */}
                      {/* <img src={blog.thumbnailUrl} alt="" /> */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="nav-bottom">
          {/* <button className="btn-big" onClick={() => handleNewClick()}>
            New Post
          </button> */}
          <button className="btn-circle" onClick={handleGoToTop}>
            Top
          </button>
        </div>
      </motion.div>
    </>
  );
}

export default React.memo(BlogBody);
