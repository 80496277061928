import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectMainMenu } from '../../modules/hompageSlice';
import { useNavigate } from 'react-router-dom';
import { scrollToTop, scrollToTopSmooth } from '../../util/scrollUtils';

//css
import '../../css/section/solutionbody.scss';
import PAGE from '../../page/page';

const SolutionBodyNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectMainMenu = (menuName: string) => {
    dispatch(setSelectMainMenu(menuName));
    navigate(`/${menuName}`);
    scrollToTop();
  };

  const handleGoToTop = useCallback(() => {
    scrollToTopSmooth();
  }, []);

  return (
    <div className="nav-bottom">
      <button className="btn-big" onClick={() => selectMainMenu(PAGE.PROJECTS)}>
        Projects
      </button>
      <button className="btn-circle" onClick={handleGoToTop}>
        Top
      </button>
    </div>
  );
};

export default React.memo(SolutionBodyNav);
