import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/modules';

import { Navigate, Route, Routes } from 'react-router-dom';
import MainBody from '../components/2D/MainBody';
import AboutBody from '../components/2D/AboutBody';
import LocationBody from '../components/2D/LocationBody';
import SolutionBodyDT from '../components/2D/SolutionBodyDT';
import SolutionBodyM from '../components/2D/SolutionBodyM';
import SolutionBodyVE from '../components/2D/SolutionBodyVE';
import ProjectsBody from '../components/2D/ProjectsBody';
import ProjectsBodyDetail from '../components/2D/ProjectsBodyDetail';
import BlogBody from '../components/2D/BlogBody';
import BlogBodyDetail from '../components/2D/BlogBodyDetail';
import ContactBody from '../components/2D/ContactBody';
import ContactBoardBody from '../components/2D/ContactBoardBody';

import PATH from './path';

const RouteHomepage = () => {
  const adminLogin = useSelector(
    (state: RootState) => state.hompate.adminLogin
  );

  return (
    <Routes>
      <Route path={PATH.HOME} element={<MainBody />} />
      <Route path={PATH.MAIN} element={<MainBody />} />
      <Route path={PATH.ABOUT_US} element={<AboutBody />} />
      <Route path={PATH.LOCATION} element={<LocationBody />} />
      <Route path={PATH.SOL_DT} element={<SolutionBodyDT />} />
      <Route path={PATH.SOL_M} element={<SolutionBodyM />} />
      <Route path={PATH.SOL_VE} element={<SolutionBodyVE />} />
      <Route path={PATH.PROJECTS_DETAIL} element={<ProjectsBodyDetail />} />
      <Route path={PATH.PROJECTS} element={<ProjectsBody />} />
      <Route path={PATH.BLOG} element={<BlogBody />} />
      <Route path={PATH.BLOG_DETAIL} element={<BlogBodyDetail />} />
      <Route path={PATH.CONTACT} element={<ContactBody />} />
      <Route path={PATH.SHOWROOM} element={<div>Showroom</div>} />
      {adminLogin && <Route path={PATH.ADMIN} element={<ContactBoardBody />} />}
      <Route path="*" element={<Navigate to={PATH.HOME} />} />
    </Routes>
  );
};

export default React.memo(RouteHomepage);
