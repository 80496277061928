import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import axiosInstance from '../../util/axiosInterceptor';
import { setSelectMainMenu } from '../../modules/hompageSlice';
import useObserver from '../../hooks/useObserver';
import { scrollToTopSmooth } from '../../util/scrollUtils';
import { useAnimation, motion } from 'framer-motion';
import { opacityDefault, opacityLeft } from '../../styles/animation';

//css
import '../../css/section/aboutbody.scss';
function AboutBody({}) {
  const gotoTop = () => {
    scrollToTopSmooth();
  };
  const dispatch = useDispatch();
  // const selectMainMenu = (menuName) => {
  //   dispatch(setSelectMainMenu(menuName));
  // };
  const { ref: ref1, animation: animation1 } = useObserver(0.1);
  const { ref: ref2, animation: animation2 } = useObserver(0.5);
  const { ref: ref3, animation: animation3 } = useObserver(0.5);
  const { ref: ref4, animation: animation4 } = useObserver(0.5);
  const { ref: ref5, animation: animation5 } = useObserver(0.5);
  return (
    <main className="wrapper-body aboutbody">
      <motion.div
        ref={ref1}
        initial="hidden"
        animate={animation1}
        variants={opacityLeft}
        className="wrap-about-greeting"
      >
        <div className="layout">
          <div className="wrap-title-con wrap-about-title">
            <div className="title-name">
              <h1>About Us</h1>
            </div>
            <div className="title-des con-about-des">
              <p className="font-accent">
                <Trans ns="about_body">
                  우리의 비전은 모든 정보를 지능화하고 설명하는 것입니다.
                </Trans>
              </p>
              <p className="font-accent">
                <Trans ns="about_body">
                  우리의 미션은 전세계의 데이터를 디지털 트윈을 통해&nbsp;
                  <span>체계화하고</span>
                  <br />
                  <span>누구나 편리하게 이용</span>할 수 있도록 지식화하는
                  것입니다.
                </Trans>
              </p>
            </div>
          </div>
          <div className="bg-img"></div>
        </div>
      </motion.div>
      <div className="wrap-con-con wrap-about-con">
        <div className="wrap-buisiness-areas">
          <div className="layout">
            <div className="con-header">
              <h2>Business Areas</h2>
            </div>

            <div className="con-body wrap-about-buisiness-areas-body">
              <div className="wrap-about-img">
                <div className="con-about-img">
                  <motion.div
                    ref={ref2}
                    initial="hidden"
                    animate={animation2}
                    variants={opacityDefault}
                    className="about-img"
                  >
                    <img src="/images/img_about_digital_twin.png" alt="" />
                  </motion.div>
                </div>
                <div className="con-about-img">
                  <motion.div
                    ref={ref3}
                    initial="hidden"
                    animate={animation3}
                    variants={opacityDefault}
                    className="about-img"
                  >
                    <img src="/images/img_about_metaverse.png" alt="" />
                  </motion.div>
                </div>
                <div className="con-about-img">
                  <motion.div
                    ref={ref4}
                    initial="hidden"
                    animate={animation4}
                    variants={opacityDefault}
                    className="about-img"
                  >
                    <img
                      src="/images/img_about_virtual_exhibition.png"
                      alt=""
                    />
                  </motion.div>
                </div>
                <div className="con-about-img">
                  <motion.div
                    ref={ref5}
                    initial="hidden"
                    animate={animation5}
                    variants={opacityDefault}
                    className="about-img"
                  >
                    <img src="/images/img_about_vr.png" alt="" />
                  </motion.div>
                </div>
              </div>
              <div className="wrap-about-text">
                <div className="con-about-text">
                  <div className="about-text">
                    <h4>
                      <Trans ns="about_body">
                        디지털 트윈 기반 관제 / 시뮬레이션
                      </Trans>
                    </h4>
                    <p>
                      <Trans ns="about_body">
                        디지털 트윈은 현실과 동일한 가상의 공간에 현실의
                        데이터를 연결하여 가상에서 현실을 관제하고, 시뮬레이션
                        할 수 있는 기술입니다.
                      </Trans>{' '}
                      <Trans ns="about_body">
                        아이지아이 코리아는 디지털 트윈을 4차 산업 혁명 시대
                        산업 현장 혁신의 기반 기술로 정의하고 AI 기반 관제,
                        빅데이터 수집/분석 등 다양한 최신 기술을 적용하기 위한
                        고확장성 플랫폼으로 설계하였습니다.
                      </Trans>{' '}
                      <Trans ns="about_body">
                        이렇게 만들어진 디지털 트윈 솔루션 OSS는 데이터 가시화
                        및 AI 기반 의사 결정 지원 기능을 통해 XX개 기관에서
                        혁신적인 경영/관리 판단 지원 도구로 사용 중입니다.
                      </Trans>
                    </p>
                  </div>
                </div>
                <div className="con-about-text">
                  <div className="about-text">
                    <h4>
                      <Trans ns="about_body">
                        메타버스 체험형 교육 서비스 / 가상 체험 학습 중심
                        에듀테크 서비스
                      </Trans>
                    </h4>
                    <p>
                      <Trans ns="about_body">
                        에듀테크는 교육(education)과 기술(Technology)의 합성어로
                        인공지능, 확장현실, 가상화 기술 등을 활용한 ICT 기반
                        차세대 교육 시스템을 의미합니다.
                      </Trans>{' '}
                      <Trans ns="about_body">
                        아이지아이 코리아는 가상 체험 기반의 에듀테크 솔루션을
                        제공하여 학습자의 학습 의욕을 제고하고, 살아 숨쉬는
                        지식을 제공하는데 집중하고 있습니다.
                      </Trans>
                    </p>
                  </div>
                </div>
                <div className="con-about-text">
                  <div className="about-text">
                    <h4>
                      <Trans ns="about_body">
                        체험형 디지털 브랜딩 스페이스
                      </Trans>
                    </h4>
                    <p>
                      <Trans ns="about_body">
                        아이지아이 코리아는 상품, 공간, 소리, 체험에 이르기까지
                        사용자가 모든 감각을 브랜드 체험에 몰입할 수 있는 브랜딩
                        공간을 제공합니다.
                      </Trans>{' '}
                      <Trans ns="about_body">
                        사용자는 브랜드의 정체성과 철학이 반영된 가상 공간을
                        통해 브랜드 가치를 체험할 수 있습니다.
                      </Trans>
                    </p>
                  </div>
                </div>
                <div className="con-about-text">
                  <div className="about-text">
                    <h4>
                      <Trans ns="about_body">
                        XR 디바이스를 통한 가상 체험 영역 확장
                      </Trans>
                    </h4>
                    <p>
                      <Trans ns="about_body">
                        XR(Extended Reality)은 VR(Virtiual Reality),
                        AR(Augmented Reality), MR(Mixed Reality)을 망라하는
                        초실감 몰입형 기술을 말합니다.
                      </Trans>{' '}
                      <Trans ns="about_body">
                        아이지아이 코리아는 XR의 몰입 효과를 산업, 교육, 체험
                        현장에 접목하여 신기함과 새로움을 넘어 가장 효과적인
                        콘텐츠를 만들어낼 수 있다고 믿습니다.
                      </Trans>{' '}
                      <Trans ns="about_body">
                        앞으로도 아이지아이 코리아의 XR팀은 우주에서 반도체
                        공정에 이르기까지 인류의 모든 도달 영역을 사람들의 일상
                        안으로 옮기는데 최선을 다하겠습니다.
                      </Trans>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap-partner">
          <div className="layout">
            <div className="con-header">
              <h2>Partner</h2>
            </div>
            <div className="con-body wrap-about-partner-body">
              <div className="wrap-partner-img">
                <div className="con-partner-img">
                  <img src="/images/logo/logo_partner_1.svg" alt="" />
                </div>
              </div>
              <div className="wrap-partner-text">
                <h4>ISSAC Engineering</h4>
                <p>
                  ISSAC Engineering was founded in January 2007 as an
                  engineering service company specializing in optimal solutions
                  for Process and Factory automation.
                  <br />
                  The implementation of Smart Factory with integrated production
                  system became essential due to diversification of industrial
                  and market environment following the 4th Industrial
                  Revolution.
                  <br /> To respond to this change, we are expanding our field
                  of expertise as a hybrid OT Solution provider by combining
                  existing OT expertise with IT technologies to provide
                  engineering services (Design, Manufacturing, Installation,
                  Commissioning and Maintenance) in the Smart Factory, Smart
                  Manufacturing, and Energy fields.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap-clients">
          <div className="layout">
            <div className="con-header">
              <h2>Clients</h2>
            </div>
            <div className="con-body wrap-about-clinets-body">
              <img src="/images/logo/logo_client_1.svg" alt="" />
              <img src="/images/logo/logo_client_2.svg" alt="" />
              <img src="/images/logo/logo_client_3.svg" alt="" />
              <img src="/images/logo/logo_client_4.svg" alt="" />
              <img src="/images/logo/logo_client_5.svg" alt="" />
              <img src="/images/logo/logo_client_6.svg" alt="" />
              <img src="/images/logo/logo_client_7.svg" alt="" />
              <img src="/images/logo/logo_client_8.svg" alt="" />
              <img src="/images/logo/logo_client_9.svg" alt="" />
              <img src="/images/logo/logo_client_10.svg" alt="" />
              <img src="/images/logo/logo_client_11.svg" alt="" />
              <img src="/images/logo/logo_client_12.svg" alt="" />
              <img src="/images/logo/logo_client_13.svg" alt="" />
              <img src="/images/logo/logo_client_14.svg" alt="" />
              <img src="/images/logo/logo_client_15.svg" alt="" />
              <img src="/images/logo/logo_client_16.svg" alt="" />
              <img src="/images/logo/logo_client_17.svg" alt="" />
              <img src="/images/logo/logo_client_18.svg" alt="" />
              <img src="/images/logo/logo_client_19.svg" alt="" />
              <img src="/images/logo/logo_client_20.svg" alt="" />
              <img src="/images/logo/logo_client_21.svg" alt="" />
              <img src="/images/logo/logo_client_22.svg" alt="" />
              <img src="/images/logo/logo_client_23.svg" alt="" />
              <img src="/images/logo/logo_client_24.svg" alt="" />
              <img src="/images/logo/logo_client_25.svg" alt="" />
              <img src="/images/logo/logo_client_26.svg" alt="" />
              <img src="/images/logo/logo_client_27.svg" alt="" />
              <img src="/images/logo/logo_client_28.svg" alt="" />
              <img src="/images/logo/logo_client_29.svg" alt="" />
              <img src="/images/logo/logo_client_30.svg" alt="" />
              <img src="/images/logo/logo_client_31.svg" alt="" />
              <img src="/images/logo/logo_client_32.svg" alt="" />
              <img src="/images/logo/logo_client_33.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="nav-bottom">
        <button className="btn-circle" onClick={gotoTop}>
          Top
        </button>
      </div>
    </main>
  );
}

export default React.memo(AboutBody);
