import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import { setSelectMainMenu } from '../../modules/hompageSlice';
import axios from 'axios';
import dayjs from 'dayjs';
import { opacityLeft } from '../../styles/animation';
import { motion } from 'framer-motion';
import useObserver from '../../hooks/useObserver';
import { scrollToTop } from '../../util/scrollUtils';
import { v4 } from 'uuid';

//css
import '../../css/section/contactbody.scss';

function ContactBody({}) {
  const { t } = useTranslation('contact_body');
  const [contactList, setContactList] = useState();
  const [sendContactStats, setSendContactStats] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectMainMenu = (menuName: string) => {
    dispatch(setSelectMainMenu(menuName));
    navigate(`/${menuName}`);
    scrollToTop();
  };

  useEffect(() => {
    getContactList();
  }, []);

  const getContactList = () => {
    axios
      .get(
        'https://6rzwkgf5p8.execute-api.ap-northeast-2.amazonaws.com/igi/contact'
      )
      .then((response) => {
        setContactList(response.data.Items);
        // console.log('Contact 리스트', response.data.Items);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const [componyName, setComponyName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNum, setPhoneNum] = useState('');
  const [questionsText, setQuestionsText] = useState('');
  const onChangeComponyName = (e) => {
    setComponyName(e.currentTarget.value);
  };
  const onChangeName = (e) => {
    setName(e.currentTarget.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.currentTarget.value);
  };
  const onChangePhoneNum = (e) => {
    setPhoneNum(e.currentTarget.value);
  };
  const onChangeQuestionsText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestionsText(e.currentTarget.value);
    // textarea 높이 조절
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  };

  const setContact = () => {
    const postData = {
      id: v4(),
      componyName: componyName,
      name: name,
      email: email,
      phoneNum: phoneNum,
      questionsText: questionsText,
      date: dayjs().format('YYYY-MM-DD HH:mm'),
    };
    if (sendContactStats === true) return;
    setSendContactStats(true);
    // console.log('qqqqq', postData);
    // 문의하기 내용 DB에 저장
    axios
      .post(
        'https://6rzwkgf5p8.execute-api.ap-northeast-2.amazonaws.com/igi/contact',
        postData
      )
      .then((response) => {
        console.log('포스트', response.data);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });

    // 문의하기 내용 메일로 전송
    axios
      .post(
        'https://6rzwkgf5p8.execute-api.ap-northeast-2.amazonaws.com/igi/contact-mail',
        postData
      )
      .then((response) => {
        alert('문의사항이 성공적으로 신청되었습니다.');
        window.location.reload();
        setSendContactStats(false);
        // console.log(response.data);
      })
      .catch((error) => {
        alert('문의사항 신청에 실패했습니다.');
        console.error('There was an error!', error);
        setSendContactStats(false);
      });
  };
  const { ref: ref1, animation: animation1 } = useObserver(0.01);
  return (
    <motion.div
      ref={ref1}
      initial="hidden"
      animate={animation1}
      variants={opacityLeft}
      className="wrapper-body contactbody"
    >
      <div className="layout">
        <div className="wrap-title-con wrap-contact-title">
          <h1>Contact Us</h1>
        </div>

        <div className="wrap-con-con wrap-contact-con">
          <div className="con-header wrap-contact-header">
            <h4>
              <Trans ns="contact_body">
                안녕하세요. 아이지아이코리아입니다.
                <br />
                담당자 확인 후 남겨주신 문의사항에 대해 연락드리겠습니다.
              </Trans>
            </h4>
          </div>
          <div className="con-body wrap-contact-body">
            <div className="con-input-text con-context-input-text">
              <p className="font-medium">
                <Trans ns="contact_body">회사/상호명</Trans>
              </p>
              <input
                className="font-big"
                type="text"
                maxLength={30}
                value={componyName}
                onChange={onChangeComponyName}
                placeholder={t('내용을 입력하세요.')}
              />
            </div>
            <div className="con-input-text con-context-input-text">
              <p className="font-medium">
                <Trans ns="contact_body">성함</Trans>
              </p>
              <input
                className="font-big"
                type="text"
                maxLength={30}
                value={name}
                onChange={onChangeName}
                placeholder={t('내용을 입력하세요.')}
              />
            </div>
            <div className="con-input-text con-context-input-text">
              <p className="font-medium">
                <Trans ns="contact_body">이메일</Trans>
              </p>
              <input
                className="font-big"
                type="emall"
                maxLength={30}
                value={email}
                onChange={onChangeEmail}
                placeholder={t('내용을 입력하세요.')}
              />
            </div>
            <div className="con-input-text con-context-input-text">
              <p className="font-medium">
                <Trans ns="contact_body">연락처</Trans>
              </p>
              <input
                className="font-big"
                type="tel"
                maxLength={30}
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                value={phoneNum}
                onChange={onChangePhoneNum}
                placeholder={t('내용을 입력하세요.')}
              />
            </div>
            <div className="con-input-textarea con-context-input-textarea">
              <p className="font-medium">
                <Trans ns="contact_body">문의사항</Trans>
              </p>
              <textarea
                className="font-big"
                ref={textareaRef}
                value={questionsText}
                onChange={onChangeQuestionsText}
                placeholder={t('내용을 입력하세요.')}
              />
            </div>
          </div>
          <div className="con-footer">
            <div className="wrap-btn">
              <button className="btn-big" onClick={() => setContact()}>
                <Trans ns="contact_body">문의 저장</Trans>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="nav-bottom"></div>
    </motion.div>
  );
}

export default React.memo(ContactBody);
