export enum CATEGORY {
  ALL = "All",
  DT = "Digital Twin",
  META = "Metaverse",
  VE = "Virtual Exhibition",
  ETC = "ETC",
}

export enum SOURCE_TYPE {
  IMG = "img",
  VIDEO = "video",
}

export interface InitialProjects {
  id: number;
  name: string;
  category: CATEGORY[];
  period: string;
  image: string;
  detail: ProjectsDetail;
  playLink: string;
}

export interface ProjectsDetail {
  title: string;
  subTitle: string;
  HighlightImg: string[];
  source: string;
  sourceType: SOURCE_TYPE;
}

export const categories = [
  CATEGORY.ALL,
  CATEGORY.DT,
  CATEGORY.META,
  CATEGORY.VE,
  CATEGORY.ETC,
];
