enum PAGE {
  MAIN = 'main',
  ABOUT_US = 'about',
  LOCATION = 'Location',
  SOL_DT = 'solutiondt',
  SOL_M = 'solutionm',
  SOL_VE = 'solutionve',
  PROJECTS = 'projects',
  PROJECTS_DETAIL = 'projectsdetail',
  BLOG = 'blog',
  BLOG_DETAIL = 'blogdetail',
  CONTACT = 'contact',
  SHOWROOM = 'showroom',
  ADMIN = 'admin',
}

export default PAGE;
