import ReactDOM from 'react-dom/client';
import './index.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './modules';
import Main from './page/Main';
// import './css/common.scss';
import PrivateRoute from './PrivateRoute';
import './locales/i18n'; // 다국어 임포트

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Router>
    <Provider store={store}>
      <Routes>
        <Route element={<PrivateRoute />}>
          {/* <Route path="3D" element={<Scene></Scene>}></Route>
          <Route path="3D/play" element={<Scene></Scene>}></Route>
          <Route
            path="builder"
            element={
              <>
                <Builder></Builder>
                <Save></Save>
              </>
            }
          ></Route>
          <Route path="dashboard" element={<Dashboard></Dashboard>}></Route> */}
        </Route>
        <Route path="main" element={<Main></Main>}></Route>
        {/* <Route path="signin" element={<Signin></Signin>}></Route> */}
        <Route path="*" element={<Main></Main>}></Route>
        {/* <Route path="*" element={<Builder></Builder>}></Route> */}
      </Routes>
    </Provider>
  </Router>
);
