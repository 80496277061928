enum PATH {
  HOME = '/', // Home page
  MAIN = '/main', // Main page
  ABOUT_US = '/about',
  LOCATION = '/location', // About Us page
  SOL_DT = '/solutiondt', // Digital Twin solution page
  SOL_M = '/solutionm', // Metaverse solution page
  SOL_VE = '/solutionve', // Virtual Exhibition solution page
  PROJECTS = '/projects', // Projects page
  PROJECTS_DETAIL = '/projects/:id', // Project details page
  BLOG = '/blog', // Blog page
  BLOG_DETAIL = '/blog/:id', // Blog page
  CONTACT = '/contact', // Contact page
  SHOWROOM = '/showroom', // Showroom page
  ADMIN = '/admin', // Admin page
}

export default PATH;
