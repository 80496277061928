import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import axiosInstance from '../../util/axiosInterceptor';
import { setSelectMainMenu } from '../../modules/hompageSlice';
import { opacityLeft } from '../../styles/animation';
import { motion } from 'framer-motion';
import useObserver from '../../hooks/useObserver';

import SolutionBodyProjects from './SolutionBodyProjects';
import SolutionBodyNav from './SolutionBodyNav';

//css
import '../../css/section/solutionbody.scss';

function SolutionBody({}) {
  const { ref: ref1, animation: animation1 } = useObserver(0.01);
  return (
    <motion.div
      ref={ref1}
      initial="hidden"
      animate={animation1}
      variants={opacityLeft}
      className="wrapper-body solutionbody digital-twin"
    >
      <div className="wrap-solutions-greeting">
        <div className="bg-img"></div>

        <div className="bg-grad-bottom"></div>
        <div className="bg-grad-left"></div>
        <div className="wrap-title-con wrap-solutions-title">
          <div className="layout">
            <div className="title-name con-soutions-title">
              <h4>Solution 1</h4>
              <h1>Digital Twin</h1>
            </div>
            <div className="title-des con-soutions-des">
              <p className="font-accent">
                <Trans ns="solution_body_digitaltwin">
                  현실에 존재하는 공간이나
                  <br />
                  물체를 데이터화하여
                  <br />
                  <span>가상 세계에 구현하는 기술</span>을 의미합니다.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="wrap-con-con wrap-solutions-con">
        <div className="wrap-solutions-introduce">
          <div className="bg-solutions-introduce">
            <div className="layout">
              <img src="/images/bg_solutiondt2.png" alt="" />
            </div>
          </div>
          <div className="layout">
            <div className="con-body wrap-solutions-introduce-body">
              <h3>
                <Trans ns="solution_body_digitaltwin">
                  아이지아이 코리아는
                </Trans>
              </h3>
              <p className="font-accent">
                <Trans ns="solution_body_digitaltwin">
                  <span>
                    ‘전세계의 데이터를 디지털 트윈을 통해 체계화하고 누구나
                    편리하게 이용할 수 있도록 지식화 한다.’
                  </span>
                  는 <br />
                  미션을 현실화하기 위해 누구나, 어디서나 사용 가능한 디지털
                  트윈 솔루션인 OSS를 개발하여 스마트 팩토리,
                  <br />
                  스마트 팜 등 산업 현장의 DX(디지털트랜스포메이션) 가속에
                  앞장서고 있습니다.
                </Trans>
              </p>
            </div>
          </div>
        </div>
        <div className="wrap-solutions-feature">
          <div className="layout">
            <div className="con-header">
              <h2>Feature</h2>
            </div>
            <div className="con-body wrap-solutions-feature-body">
              <div className="con-feature-card">
                <div className="con-title">
                  <span className="ico-monitor"></span>

                  <p className="font-big">
                    <Trans ns="solution_body_digitaltwin">
                      웹/모바일/XR 통합 환경 제공
                    </Trans>
                  </p>
                </div>
                <div className="con-des">
                  <p>
                    <Trans ns="solution_body_digitaltwin">
                      온트윈즈 솔루션은 사용자가 언제 어디서라도 현장을
                      실시간으로 모니터링하고 관제할 수 있도록 모바일, VR, PC 각
                      플랫폼에 최적화된 UI 및 조작 기능을 제공합니다.
                    </Trans>
                  </p>
                </div>
              </div>
              <div className="con-feature-card">
                <div className="con-title">
                  <span className="ico-ring"></span>
                  <p className="font-big">
                    <Trans ns="solution_body_digitaltwin">
                      유연한 배치와 변경
                    </Trans>
                  </p>
                </div>
                <div className="con-des">
                  <p>
                    <Trans ns="solution_body_digitaltwin">
                      온트윈즈 솔루션은 사용자가 디지털 트윈 구축 대상의 변화에
                      유연하게 대응할 수 있도록 가상 환경을 쉽게 수정할 수 있는
                      빌더 기능을 제공합니다.
                    </Trans>
                  </p>
                </div>
              </div>
              <div className="con-feature-card">
                <div className="con-title">
                  <span className="ico-brain"></span>
                  <p className="font-big">
                    <Trans ns="solution_body_digitaltwin">
                      AI 분석 및 시뮬레이션 환경 지원
                    </Trans>
                  </p>
                </div>
                <div className="con-des">
                  <p>
                    <Trans ns="solution_body_digitaltwin">
                      온트윈즈 솔루션은 관리자의 의사결정을 지원하기 위해
                      효율적인 데이터 가시화 기능 및 간편한 관제 기능을
                      제공합니다.
                    </Trans>
                  </p>
                </div>
              </div>
              <div className="con-feature-card">
                <div className="con-title">
                  <span className="ico-cloud"></span>
                  <p className="font-big">
                    <Trans ns="solution_body_digitaltwin">
                      주요 클라우드 지원
                    </Trans>
                  </p>
                </div>
                <div className="con-des">
                  <p>
                    <Trans ns="solution_body_digitaltwin">
                      온트윈즈 솔루션은 구축 비용 절감을 위해 AWS, 네이버
                      클라우드 플랫폼 등 클라우드 서비스를 지원하여 자체 서버
                      없이 시스템 구축이 가능합니다.
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="wrap-solutions-demo">
          <div className="con-header">
            <h2>Play Demo</h2>
          </div>
          <div className="con-body">
            <div className="wrap-demo-card">
              <div className="con-demo-card">
                <div className="bg-demo-img"></div>
                <div className="layout">
                  <div className="con-img">
                    <img src="/images/img_oss_builder.png" />
                  </div>
                  <div className="con-des">
                    <div className="wrap-text">
                      <h4>디지털 트윈 기반 관제 / 시뮬레이션</h4>
                      <p>
                        초보자들도 게임처럼 쉽고 빠르게 공장, 물류창고 등의
                        시설물을 쉽게 구축할 수 있는 디지털 트윈 솔루션입니다.
                      </p>
                    </div>
                    <div className="wrap-btn">
                      <button className="btn-big">Play</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <SolutionBodyProjects />
      </div>
      <SolutionBodyNav />
    </motion.div>
  );
}

export default React.memo(SolutionBody);
