import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

function ContactBoardBody({}) {
  const dispatch = useDispatch();
  const [allContacts, setAllContacts] = useState([]);

  useEffect(() => {
    // 겟
    axios
      .get(
        'https://6rzwkgf5p8.execute-api.ap-northeast-2.amazonaws.com/igi/contact'
      )
      .then((response) => {
        setAllContacts(response.data.Items);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  }, []);

  // 페이징 관련 상태
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;
  // 현재 페이지의 데이터 계산
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allContacts.slice(indexOfFirstItem, indexOfLastItem);
  // 페이지 번호를 클릭했을 때 핸들러
  const handleClick = (pageNumber) => setCurrentPage(pageNumber);
  // 페이지 번호 리스트 생성
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(allContacts.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  // 셀렉트 문의건
  const [selectItemId, setSelectItemId] = useState('');
  const selectItem = (id) => {
    setSelectItemId(id);
  };

  return (
    <div>
      <h1>문의 사항 게시판</h1>
      <div>
        <table>
          <tbody
            style={{
              color: 'black',
            }}
          >
            {currentItems.map((item) => (
              <>
                <tr onClick={() => selectItem(item.id)}>
                  <td> {item?.date ?? 'null'}</td>
                  <td>회사/상호명: {item.componyName}</td>
                  <td>이름:{item.name}</td>
                </tr>
                {selectItemId === item.id && (
                  <>
                    <tr>
                      <td>이메일:{item.email}</td>
                      <td>전화번호:{item.phoneNum}</td>
                    </tr>
                    <tr>
                      <td>내용:{item.questionsText}</td>
                    </tr>
                  </>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        {pageNumbers.map((number) => (
          <button key={number} onClick={() => handleClick(number)}>
            {number}
          </button>
        ))}
      </div>
    </div>
  );
}

export default React.memo(ContactBoardBody);
