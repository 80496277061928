import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import axiosInstance from '../../util/axiosInterceptor';
import { setSelectMainMenu } from '../../modules/hompageSlice';
import { opacityLeft } from '../../styles/animation';
import { motion } from 'framer-motion';
import useObserver from '../../hooks/useObserver';

import SolutionBodyProjects from './SolutionBodyProjects';
import SolutionBodyNav from './SolutionBodyNav';

//css
import '../../css/section/solutionbody.scss';

function SolutionBody({}) {
  const { ref: ref1, animation: animation1 } = useObserver(0.1);
  const dispatch = useDispatch();
  // const selectMainMenu = (menuName) => {
  //   dispatch(setSelectMainMenu(menuName));
  // };
  return (
    <motion.div
      ref={ref1}
      initial="hidden"
      animate={animation1}
      variants={opacityLeft}
      className="wrapper-body solutionbody vitual-exhibtion"
    >
      <div className="wrap-solutions-greeting">
        <div className="bg-img"></div>

        <div className="bg-grad-bottom"></div>
        <div className="bg-grad-left"></div>
        <div className="wrap-title-con wrap-solutions-title">
          <div className="layout">
            <div className="title-name con-soutions-title">
              <h4>Solution 3</h4>
              <h1>Virtual Exhibtion</h1>
            </div>
            <div className="title-des con-soutions-des">
              <p className="font-accent">
                <Trans ns="solution_body_virtualexhibition">
                  컴퓨터 환경에서 이루어지는
                  <br />
                  <span>인공 환경에서의 전시</span>를 의미합니다.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="wrap-con-con wrap-solutions-con">
        <div className="wrap-solutions-introduce">
          <div className="bg-solutions-introduce">
            <div className="layout">
              <img src="/images/bg_solutionve2.png" alt="" />
            </div>
          </div>
          <div className="layout">
            <div className="con-body wrap-solutions-introduce-body">
              <h3>
                <Trans ns="solution_body_virtualexhibition">
                  아이지아이 코리아는
                </Trans>
              </h3>
              <p className="font-accent">
                <Trans ns="solution_body_virtualexhibition">
                  <br />
                  디지털 트윈의 네트워크 및 데이터 처리 기능과 메타버스의 뛰어난
                  멀티미디어 콘텐츠 구축 기술을 접목하여
                  <br />
                  상호작용을 통해{' '}
                  <span>사용자 경험을 극대화할 수 있는 솔루션</span>을
                  제공합니다.
                </Trans>
              </p>
            </div>
          </div>
        </div>
        <div className="wrap-solutions-feature">
          <div className="layout">
            <div className="con-header">
              <h2>Feature</h2>
            </div>
            <div className="con-body wrap-solutions-feature-body">
              <div className="con-feature-card">
                <div className="con-title">
                  <span className="ico-monitor"></span>
                  <p className="font-big">
                    <Trans ns="solution_body_virtualexhibition">
                      뛰어난 가시화 기능
                    </Trans>
                  </p>
                </div>
                <div className="con-des">
                  <p>
                    <Trans ns="solution_body_virtualexhibition">
                      온트윈즈 솔루션은 학습자가 현실에서 경험하기 힘든 실험 및
                      체험 학습을 가상
                    </Trans>
                  </p>
                </div>
              </div>
              <div className="con-feature-card">
                <div className="con-title">
                  <span className="ico-ring"></span>
                  <p className="font-big">
                    <Trans ns="solution_body_virtualexhibition">
                      웹 기반 서비스 시스템
                    </Trans>
                  </p>
                </div>
                <div className="con-des">
                  <p>
                    <Trans ns="solution_body_virtualexhibition">
                      온트윈즈 솔루션은 교육자가 변화하는 교육 환경에에 유연하게
                      대응할 수 있도록 가상 교육 공간을 간편하게 수정하고 교육
                      내용을 변경할 수 있는 빌더 및 문서 업로드 기능을
                      지원합니다.
                    </Trans>
                  </p>
                </div>
              </div>
              <div className="con-feature-card">
                <div className="con-title">
                  <span className="ico-cloud"></span>
                  <p className="font-big">
                    <Trans ns="solution_body_virtualexhibition">
                      주요 클라우드 지원
                    </Trans>
                  </p>
                </div>
                <div className="con-des">
                  <p>
                    <Trans ns="solution_body_virtualexhibition">
                      온트윈즈 솔루션은 구축 비용 절감을 위해 AWS, 네이버
                      클라우드, AZURE 등 다양한 클라우드 서비스를 지원하여 자체
                      서버 없이 시스템 구축이 가능합니다.
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SolutionBodyProjects />
      </div>
      <SolutionBodyNav />
    </motion.div>
  );
}

export default React.memo(SolutionBody);
