import React, { useEffect, useState, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import { setSelectMainMenu } from '../../modules/hompageSlice';
import { opacityLeft } from '../../styles/animation';
import { motion } from 'framer-motion';
import { initialProjects } from '../../data/projects';
import { categories, CATEGORY } from '../../util/type/projectType';
import PATH from '../../router/path';
import PAGE from '../../page/page';
import useObserver from '../../hooks/useObserver';
import { scrollToTop, scrollToTopSmooth } from '../../util/scrollUtils';

import '../../css/section/projectsbody.scss';

function ProjectsBody() {
  const showValue = 12; // 한 번에 보여줄 프로젝트 수
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(CATEGORY.ALL);
  const [projects, setProjects] = useState(initialProjects);
  const [displayCount, setDisplayCount] = useState(showValue);

  const handleGoToTop = useCallback(() => {
    scrollToTopSmooth();
  }, []);

  const dispatch = useDispatch();
  const selectMainMenu = (menuName, projectId) => {
    dispatch(setSelectMainMenu(menuName));
    navigate(`${PATH.PROJECTS}/${projectId}`);
    scrollToTop();
  };

  const handleCategoryClick = (category: CATEGORY) => {
    setSelectedCategory(category);
    scrollToTop();
    setDisplayCount(showValue);
  };

  const filteredProjects = projects
    .filter((project) =>
      selectedCategory === CATEGORY.ALL
        ? true
        : project.category.includes(selectedCategory)
    )
    .slice(0, displayCount);

  // 스크롤 이벤트 핸들러를 디바운스 처리
  const handleScroll = () => {
    if (!window.requestAnimationFrame) {
      updateScroll();
    } else {
      window.requestAnimationFrame(updateScroll);
    }
  };

  const updateScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight * 0.6 - 100
    ) {
      setDisplayCount((prevCount) => prevCount + showValue);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { ref: ref1, animation: animation1 } = useObserver(0.01);
  return (
    <motion.div
      ref={ref1}
      initial="hidden"
      animate={animation1}
      variants={opacityLeft}
      className="wrapper-body projectsbody"
    >
      <div className="wrap-title-con wrap-projects-title">
        <div className="layout">
          <h1>Projects</h1>
          <ul className="wrap-title-srot">
            {categories.map((category, index) => (
              <li
                key={index}
                className={`con-title-srot ${
                  selectedCategory === category ? 'active' : ''
                }`}
                onClick={() => handleCategoryClick(category)}
              >
                <p className="font-accent">#{category}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="wrap-con-con wrap-projects-con">
        <div className="con-body wrap-projects-body">
          <div className="layout">
            <div className="wrap-projects-cards">
              {filteredProjects.map((project, idx) => (
                <div className="con-projects-card" key={idx}>
                  <div
                    key={project.id}
                    className="con-project-card big"
                    onClick={() =>
                      selectMainMenu(PAGE.PROJECTS_DETAIL, project.id)
                    }
                  >
                    <div className="con-project-img">
                      <img src={project.image} alt={project.name} />
                    </div>
                    <div className="con-project-des">
                      <div className="wrap-project-text">
                        <p className="font-big">
                          <Trans ns="projects">{project.name}</Trans>
                        </p>
                        <p>{project.period}</p>
                      </div>
                      <div className="wrap-project-btn">
                        <span className="arr-right"></span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="nav-bottom">
        <button className="btn-circle" onClick={handleGoToTop}>
          Top
        </button>
      </div>
    </motion.div>
  );
}

export default React.memo(ProjectsBody);
