import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import axiosInstance from '../../util/axiosInterceptor';
import { setSelectMainMenu } from '../../modules/hompageSlice';
import { opacityLeft } from '../../styles/animation';
import { motion } from 'framer-motion';
import useObserver from '../../hooks/useObserver';

import SolutionBodyProjects from './SolutionBodyProjects';
import SolutionBodyNav from './SolutionBodyNav';

//css
import '../../css/section/solutionbody.scss';
import { Trans } from 'react-i18next';

function SolutionBody({}) {
  const { ref: ref1, animation: animation1 } = useObserver(0.01);
  const dispatch = useDispatch();
  // const selectMainMenu = (menuName) => {
  //   dispatch(setSelectMainMenu(menuName));
  // };
  return (
    <motion.div
      ref={ref1}
      initial="hidden"
      animate={animation1}
      variants={opacityLeft}
      className="wrapper-body solutionbody metaverse"
    >
      <div className="wrap-solutions-greeting">
        <div className="bg-img"></div>

        <div className="bg-grad-bottom"></div>
        <div className="bg-grad-left"></div>
        <div className="wrap-title-con wrap-solutions-title">
          <div className="layout">
            <div className="title-name con-soutions-title">
              <h4>Solution 2</h4>
              <h1>Metaverse</h1>
            </div>
            <div className="title-des con-soutions-des">
              <p className="font-accent">
                <Trans ns="solution_body_metaverse">
                  메타버스는 가상, 초월을 의미하는
                  <br />
                  메타(Meta)와 세계 우주를 의미하는 유니버스(Universe)의
                  <br />
                  합성어인 <span>새로운 가상 세계</span>를 의미합니다.
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="wrap-con-con wrap-solutions-con">
        <div className="wrap-solutions-introduce">
          <div className="bg-solutions-introduce">
            <div className="layout">
              <img src="/images/bg_solutionm2.png" alt="" />
            </div>
          </div>
          <div className="layout">
            <div className="con-body wrap-solutions-introduce-body">
              <h3>
                <Trans ns="solution_body_metaverse">아이지아이 코리아는</Trans>
              </h3>
              <p className="font-accent">
                <Trans ns="solution_body_metaverse">
                  <br />
                  서비스 확장성이 높은 메타버스 구축∙관리 솔루션을 통해 소비자의
                  관심을 제고하고
                  <br />
                  <span>소비자-생산자간 새로운 직접 연결의 통로</span>를
                  제공합니다.
                </Trans>
              </p>
            </div>
          </div>
        </div>
        <div className="wrap-solutions-feature">
          <div className="layout">
            <div className="con-header">
              <h2>Feature</h2>
            </div>
            <div className="con-body wrap-solutions-feature-body">
              <div className="con-feature-card">
                <div className="con-title">
                  <span className="ico-monitor"></span>
                  <p className="font-big">
                    <Trans ns="solution_body_metaverse">
                      자유로운 체험형 콘텐츠 구축
                    </Trans>
                  </p>
                </div>
                <div className="con-des">
                  <p>
                    <Trans ns="solution_body_metaverse">
                      온트윈즈 솔루션은 사용자가 현실에서 경험하기 힘든 실험 및
                      체험 콘텐츠를 가상 환경에서 제공합니다.
                    </Trans>
                  </p>
                </div>
              </div>
              <div className="con-feature-card">
                <div className="con-title">
                  <span className="ico-ring"></span>
                  <p className="font-big">
                    <Trans ns="solution_body_metaverse">
                      유연한 콘텐츠 변경
                    </Trans>
                  </p>
                </div>
                <div className="con-des">
                  <p>
                    <Trans ns="solution_body_metaverse">
                      온트윈즈 솔루션은 사용자가 변화하는 시장 환경에 유연하게
                      대응할 수 있도록 간편하게 콘텐츠를 변경할 수 있는 빌더 및
                      문서 업로드 기능을 지원합니다.
                    </Trans>
                  </p>
                </div>
              </div>
              <div className="con-feature-card">
                <div className="con-title">
                  <span className="ico-brain"></span>
                  <p className="font-big">
                    <Trans ns="solution_body_metaverse">
                      AI 기반 데이터 관리 시스템
                    </Trans>
                  </p>
                </div>
                <div className="con-des">
                  <p>
                    <Trans ns="solution_body_metaverse">
                      온트윈즈 솔루션은 AI를 통해 사용자 데이터를 분석하여
                      관리자의 데이터 기반 의사 결정을 지원합니다.
                    </Trans>
                  </p>
                </div>
              </div>
              <div className="con-feature-card">
                <div className="con-title">
                  <span className="ico-cloud"></span>
                  <p className="font-big">
                    <Trans ns="solution_body_metaverse">
                      주요 클라우드 지원
                    </Trans>
                  </p>
                </div>
                <div className="con-des">
                  <p>
                    <Trans ns="solution_body_metaverse">
                      온트윈즈 솔루션은 구축 비용 절감을 위해 AWS, 네이버
                      클라우드, AZURE 등 다양한 클라우드 서비스를 지원하여 자체
                      서버 없이 시스템 구축이 가능합니다.
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SolutionBodyProjects />
      </div>
      <SolutionBodyNav />
    </motion.div>
  );
}

export default React.memo(SolutionBody);
