export const scrollToTopSmooth = () => {
  if ('scrollBehavior' in document.documentElement.style) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  } else {
    window.scrollTo(0, 0);
  }
};

export const scrollToTop = () => {
  if ('scrollBehavior' in document.documentElement.style) {
    window.scrollTo({ top: 0 });
  } else {
    window.scrollTo(0, 0);
  }
};
